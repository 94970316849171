<template>
  <v-autocomplete
    ref="auto"
    placeholder="Seleccione un Proveedor"
    label="Proveedor"
    item-text="name"
    persistent-hint
    :loading="loading"
    item-value="id"
    v-bind:value="value"
    v-on:input="$emit('input', $event)"
    :items="providerList"
    :rules="rules"
    return-object
    :disabled="disabled"
    clearable
    :search-input.sync="search"
  ></v-autocomplete>
</template>

<script>
import requests from "@/services/requests";
import rules from "@/utils/form.rules";

export default {
  props: ["required", "value", "reset", "available", "disabled"],
  data() {
    return {
      providerList: [],
      loading: false,
      //rules
      search: "",
      rules: [],
    };
  },
  methods: {
    getProviders(param = "") {
      this.loading = true;
      let uri = "api/catalogs/providers/?search=";
      if (this.search) {
        uri += param;
      }
      if (this.available) {
        uri += "&available_voucher=" + this.available;
      }
      requests.get(uri).then((res) => {
        this.loading = false;
        this.providerList = res.data.results;
      });
    },
  },
  mounted() {
    this.getProviders();
    if (this.required == null) {
      this.rules = [];
    } else {
      this.rules = [rules.required];
    }
  },
  watch: {
    reset: function () {
      this.$refs.auto.reset();
      this.getProviders();
    },
    search: function () {
      this.getProviders(this.search);
    },
  },
};
</script>
